










































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'Strategy' })
export default class sensitiveManage extends VueBase {
  private viewDialog = false
  private previewData = ''
  private vul_levels = []
  private vul_levels_map = {}
  private dialogForm: any = {
    vul_name: '',
    vul_type: '',
    state: '',
    vul_desc: '',
    level_id: undefined,
    vul_fix: '',
  }
  private rules = {
    vul_name: [
      {
        required: true,
        message: this.$t('views.strategyManage.notnull') as string,
        trigger: 'blur',
      },
    ],
    vul_desc: [
      {
        required: true,
        message: this.$t('views.strategyManage.notnull') as string,
        trigger: 'blur',
      },
    ],
    level_id: [
      {
        required: true,
        message: this.$t('views.strategyManage.notnull') as string,
        trigger: 'blur',
      },
    ],
  }
  async preview(desc: string) {
    this.viewDialog = true
    this.previewData = desc
  }
  async getVulLevels() {
    this.loadingStart()
    const res = await this.services.setting.vul_levels()
    this.loadingDone()
    if (res.status === 201) {
      this.vul_levels = res.data
      this.vul_levels.forEach((item: any) => {
        this.$set(this.vul_levels_map, item.id, item.name_value)
      })
    } else {
      this.$message.error(res.msg)
    }
  }
  async created() {
    await this.getVulLevels()
    if (this.$route.query.id) {
      await this.getStrategy()
    }
  }
  async getStrategy() {
    const res = await this.services.setting.getStrategy(this.$route.query.id)
    if (res.status === 201) {
      this.dialogForm = res.data
    } else {
      this.$message.error(res.msg)
    }
  }
  dialogEnter() {
    const form: any = this.$refs.ruleForm
    form.validate((valid: boolean) => {
      if (valid) {
        if (this.$route.query.id) {
          this.editStrategyApi()
        } else {
          this.addStrategyApi()
        }
      }
    })
  }
  async goBack() {
    this.$router.push({ name: 'strategyManage' })
  }
  async addStrategyApi() {
    const { status, msg } = await this.services.setting.addManage({
      vul_name: this.dialogForm.vul_name,
      vul_desc: this.dialogForm.vul_desc,
      vul_fix: this.dialogForm.vul_fix,
      vul_type: this.dialogForm.vul_name,
      level_id: this.dialogForm.level_id,
      state: this.dialogForm.state,
    })
    if (status === 201) {
      this.$message.success(msg)
      this.goBack()
    } else {
      this.$message.error(msg)
    }
  }
  async editStrategyApi() {
    this.loadingStart()
    const { status, msg } = await this.services.setting.updateManage(
      this.dialogForm.id,
      {
        vul_name: this.dialogForm.vul_name,
        vul_desc: this.dialogForm.vul_desc,
        vul_fix: this.dialogForm.vul_fix,
        vul_type: this.dialogForm.vul_name,
        level_id: this.dialogForm.level_id,
        state: this.dialogForm.state,
      }
    )
    this.loadingDone()
    if (status === 201) {
      this.$message.success(msg)
      this.goBack()
    } else {
      this.$message.error(msg)
    }
  }
  get userInfo(): { username: string } {
    return this.$store.getters.userInfo
  }
}
